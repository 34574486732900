/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages

				$('.match-height').matchHeight();
				$('.match-height-by-row').matchHeight();
				$('.match-height-by-row-2').matchHeight();
				$('.match-height-by-row-3').matchHeight();

				$('[data-toggle="tooltip"]').tooltip();

				$('.jscroll > a').on('click', function () {
					var page = '#' + $(this).attr('rel');
					var speed = 750;
					$('html, body').animate({scrollTop: $(page).offset().top - ($('header.banner').outerHeight())}, speed);
					return false;
				});

				if(window.location.hash) {

					if($(window.location.hash).length) {
						$("html, body").animate({
							//scrollTop: $(window.location.hash).offset().top - $('header > .navbar').height()
							scrollTop: $(window.location.hash).offset().top - ($('header.banner').outerHeight())
						}, 750);
					}
				}


				$(window).scroll(function () {

					var windowscrolltop = $(window).scrollTop();
					if (windowscrolltop > 0) {
						$('header.banner').addClass('is_stuck');
					}
					else {
						$('header.banner').removeClass('is_stuck');
					}
				});


				//activation JS du clic sur le menu lors du dropdown hover
				$('.dropdown-toggle').click(function () {
					if ($(window).width() >= 996) {
						window.location.assign($(this).attr('href'));
					}
				});

				//gestion search popup
				$('.open-full-search, .close-full-search').click(function () {
					$('.full-screen-search').toggleClass('opened');
				});

				//carte
				if (typeof lat !== 'undefined' && typeof lon !== 'undefined') {
					$(function () {
						new Maplace({
							map_div: "#gmap",
							locations: [{
								lat: lat,
								lon: lon,
								icon: domain + '/app/themes/wp-agrimax/dist/images/map-marker.png'
							}],
							controls_on_map: false,
							map_options: {
								//set_center: [49.6493459, 6.01],
								zoom: 13
							},


							/*listeners: {
							mouseover: function(map, event) {
								map.setOptions({scrollwheel: false });
								}
							},*/

							styles: {
								'Grayscale': [{
									featureType: 'all',
									stylers: [
										{saturation: '-100'},
										{gamma: 0.50}
									]
								}],

							}
						}).Load();
					});
				}

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$('.navbar-toggle').on('click', function () {
					if ($(window).scrollTop() === 0) {
						$('header.banner').toggleClass('is_stuck');
					}
				});

				$('.owl-partenaires').owlCarousel({
					loop: true,
					//items: 6,
					dotsEach: 1,
					autoplay: 1,
					autoplayTimeout: 1000,
					smartSpeed: 500,
					autoplayHoverPause: false,
					//autoWidth: true,
					margin: 50,

					responsive: {
						0: {
							items: 2
						},
						480: {
							items: 4
						},
						768: {
							items: 6
						}
					}
				});

				$(".scroll").click(function (event) {
					event.preventDefault();
					$('html,body').animate({scrollTop: $(this.hash).offset().top - $('header.banner').outerHeight()}, 1200);
				});
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
				$('.owl-slide-home').owlCarousel({
					items: 1,
					loop: true,
					nav: false,
					//center: true,
					//autoWidth: true,
					autoplayTimeout: 5500,
					autoplayHoverPause: false,
					smartSpeed: 1500,
					//navText: ['<div class="owl-nav-btn prev"></div>','<div class="owl-nav-btn next"></div>'],
					//dotsContainer: '#dotsContainer',
					//nav: true,
					//rewind: true
					dots: false,
					rewind: true,
					autoplay: true
				});
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		},

		'exposants_salon_agrimax': {
			init: function () {

			},

			finalize: function () {
				$('#categories').change(function () {
					window.location = $(this).val();
				});
			}
		},

		'informations_pratiques_programme_salon_agrimax': {
			init: function () {

				var stickyNav = function () {
					var scrollTop = $(window).scrollTop();
					var planning = $('.planningSalon-link');

					if (scrollTop > stickyNavTop) {
						planning.addClass('sticky');
						planning.css('margin-top', $('header').outerHeight());
					} else {
						planning.removeClass('sticky');
						planning.css('margin-top', 0);
					}
				};

				if ($('.planningSalon-link').length) {
					var stickyNavTop = $('.planningSalon-link').offset().top;
				}

				$(window).scroll(function () {
					if (stickyNavTop) {
						stickyNav();
					}
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
